import { Setting } from 'src/app/feature/Settings/models/Setting';

export const LIST_SETTINGS = 'LIST_SETTINGS';
export const ADD_SETTING = 'ADD_SETTING';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';

interface ActionListSettings {
  type: typeof LIST_SETTINGS;
  payload: Setting[];
}

interface ActionAddSetting {
  type: typeof ADD_SETTING;
  payload: Setting;
}

interface ActionUpdateSetting {
  type: typeof UPDATE_SETTING;
  payload: Setting;
}

interface ActionClearSettings {
  type: typeof CLEAR_SETTINGS;
}

export type TypesActionsSettings =
  | ActionListSettings
  | ActionAddSetting
  | ActionUpdateSetting
  | ActionClearSettings;
