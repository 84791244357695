import {
  Order,
  ResponseOrdersProps,
  OrdersList
} from 'src/app/feature/Orders/models/Orders';

export const LIST_ORDERS = 'LIST_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_PANEL = 'SET_PANEL';
export const ORDER_NOTIFICATIONS = 'ORDER_NOTIFICATIONS';
export const CLEAR_ORDER_NOTIFICATIONS = 'CLEAR_ORDER_NOTIFICATIONS';
export const HIDE_VOID_ORDER = 'HIDE_VOID_ORDER';

interface ActionListOrders {
  type: typeof LIST_ORDERS;
  payload: ResponseOrdersProps;
}

interface ActionAddOrders {
  type: typeof ADD_ORDER;
  payload: Order;
}
interface PanelOrders {
  type: typeof SET_PANEL;
  payload: Order[];
}

interface ActionDeleteOrders {
  type: typeof DELETE_ORDER;
  payload: string;
}

interface ActionUpdateOrders {
  type: typeof UPDATE_ORDER;
  payload: Order;
}

interface ActionOrderNotifications {
  type: typeof ORDER_NOTIFICATIONS;
  payload: OrdersList[];
}

interface ActionClearOrderNotifications {
  type: typeof CLEAR_ORDER_NOTIFICATIONS;
}

interface ActionHideVoidOrder {
  type: typeof HIDE_VOID_ORDER;
  payload: Order;
}

export type TypesActionsOrders =
  | ActionListOrders
  | ActionAddOrders
  | ActionDeleteOrders
  | ActionUpdateOrders
  | PanelOrders
  | ActionOrderNotifications
  | ActionClearOrderNotifications
  | ActionHideVoidOrder;
