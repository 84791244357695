import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SidebarLayout from 'src/app/shared/layouts/SidebarLayout';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Dashboards

const Dashboard = Loader(
  lazy(() => import('src/app/feature/DashBoard/pages/Main'))
);

// Applications

const Transactions = Loader(
  lazy(() => import('src/app/shared/content/applications/Transactions'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);

// Food categories

const FoodCategories = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Main/pages/Main'))
);
const FoodCategoriesCreate = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Create/pages/Main'))
);
const FoodCategoriesEdit = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Edit/pages/Main'))
);
// Food complements

const FoodComplements = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Main/pages/Main'))
);
const FoodComplementsCreate = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Create/pages/Main'))
);
const FoodComplementsEdit = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Edit/pages/Main'))
);
// Tables

const Tables = Loader(
  lazy(() => import('src/app/feature/Tables/Main/pages/Main'))
);
const TablesCreate = Loader(
  lazy(() => import('src/app/feature/Tables/Create/pages/Main'))
);
const TablesEdit = Loader(
  lazy(() => import('src/app/feature/Tables/Edit/pages/Main'))
);

//foods

const Foods = Loader(
  lazy(() => import('src/app/feature/Foods/Main/pages/Main'))
);
const FoodsCreate = Loader(
  lazy(() => import('src/app/feature/Foods/Create/pages/Main'))
);
const FoodsEdit = Loader(
  lazy(() => import('src/app/feature/Foods/Edit/pages/Main'))
);

// Users

const Users = Loader(
  lazy(() => import('src/app/feature/Users/Main/pages/Main'))
);
const UsersCreate = Loader(
  lazy(() => import('src/app/feature/Users/Create/pages/Main'))
);

const UsersEdit = Loader(
  lazy(() => import('src/app/feature/Users/Edit/pages/Main'))
);
// Accounts

const AccountsDetail = Loader(
  lazy(() => import('src/app/feature/Accounts/Detail/pages/Main'))
);

const AccountsEdit = Loader(
  lazy(() => import('src/app/feature/Accounts/Edit/pages/Main'))
);

// Orders

const Orders = Loader(
  lazy(() => import('src/app/feature/Orders/Main/pages/Main'))
);
const OrdersDetail = Loader(
  lazy(() => import('src/app/feature/Orders/Detail/pages/Main'))
);
const OrdersCreate = Loader(
  lazy(() => import('src/app/feature/Orders/Create/pages/Main'))
);

// Reportes
const Report = Loader(
  lazy(() => import('src/app/feature/Report/Main/pages/Main'))
);

//Profile

const Profile = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

//Payments
const PaymentsCreate = Loader(
  lazy(() => import('src/app/feature/Payments/Create/pages/Main'))
);

const PaymentsMain = Loader(
  lazy(() => import('src/app/feature/Payments/Main/pages/Main'))
);

const Assistance = Loader(
  lazy(() => import('src/app/feature/Assistance/Main/pages/Main'))
);

//Settings

const KitchenSettings = Loader(
  lazy(() => import('src/app/feature/Settings/KitchenSettings/pages/Main'))
);

const routesProtected: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Users />
      },
      {
        path: '/create',
        element: <UsersCreate />
      },
      {
        path: '/:id/edit',
        element: <UsersEdit />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'assistance',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Assistance />
      }
    ]
  },
  {
    path: 'account',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <AccountsDetail />
      },
      {
        path: '/edit',
        element: <AccountsEdit />
      },
      {
        path: 'foodCategories',
        children: [
          {
            path: '/',
            element: <FoodCategories />
          },
          {
            path: '/create',
            element: <FoodCategoriesCreate />
          },
          {
            path: '/edit/:id',
            element: <FoodCategoriesEdit />
          }
        ]
      },
      {
        path: '/foodComplements',
        children: [
          {
            path: '/',
            element: <FoodComplements />
          },
          {
            path: '/create',
            element: <FoodComplementsCreate />
          },
          {
            path: '/:idFc/edit',
            element: <FoodComplementsEdit />
          }
        ]
      },
      {
        path: '/tables',
        children: [
          {
            path: '/',
            element: <Tables />
          },
          {
            path: '/create',
            element: <TablesCreate />
          },
          {
            path: '/:idT/edit',
            element: <TablesEdit />
          }
        ]
      },
      {
        path: '/orders',
        children: [
          {
            path: '/',
            element: <Orders />
          },
          {
            path: '/:idOrder',
            element: <OrdersDetail />
          },
          {
            path: '/create',
            element: <OrdersCreate />
          }
        ]
      },
      {
        path: '/foods',
        children: [
          {
            path: '/',
            element: <Foods />
          },
          {
            path: '/create',
            element: <FoodsCreate />
          },
          {
            path: '/:idF/edit',
            element: <FoodsEdit />
          }
        ]
      },
      {
        path: '/payments',
        children: [
          { path: '/', element: <PaymentsMain /> },
          {
            path: '/:id/create',
            element: <PaymentsCreate />
          }
        ]
      },
      {
        path: '/settings',
        children: [
          {
            path: '/',
            element: <KitchenSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/management/transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      }
    ]
  },
  {
    path: 'report',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Report />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  }
];

export default routesProtected;
