import {
  ADD_USER,
  LIST_USER,
  UPDATE_USER,
  TypesActionsUsers
} from '../../actions/users/UsersTypesActions';
import { UserState } from '../../models/user/UserState';
import { UserInitialValues } from 'src/app/feature/Users/interfaces/interfaces';

const initialState: UserState = {
  users: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  user: UserInitialValues
};

export default function usersReducer(
  state = initialState,
  action: TypesActionsUsers
) {
  switch (action.type) {
    case LIST_USER: {
      const users = action.payload;
      return {
        ...state,
        users
      };
    }
    case ADD_USER: {
      const user = action.payload;
      return {
        ...state,
        user
      };
    }
    case UPDATE_USER: {
      const user = action.payload;
      const index = state.users.docs.findIndex((e) => e._id === user._id);
      const newArray = [...state.users.docs];
      newArray[index] = user;
      const { docs, ...rest } = state.users;
      const users = {
        docs: newArray,
        ...rest
      };
      return {
        ...state,
        users
      };
    }
    default:
      return state;
  }
}
