import {
  Assistance,
  ResponseAssistanceProps
} from 'src/app/feature/Assistance/models/Assistance';

export const LIST_ASSISTANCES = 'LIST_ASSISTANCES';
export const UPDATE_ASSISTANCE = 'UPDATE_ASSISTANCE';
export const ASSISTANCE_NOTIFICATIONS = 'ASSISTANCE_NOTIFICATIONS';
export const CLEAR_ASSISTANCE_NOTIFICATIONS = 'CLEAR_ASSISTANCE_NOTIFICATIONS';

interface ActionListAssistance {
  type: typeof LIST_ASSISTANCES;
  payload: ResponseAssistanceProps;
}

interface ActionDeleteAssistance {
  type: typeof UPDATE_ASSISTANCE;
  payload: string;
}

interface ActionAssistanceNotifications {
  type: typeof ASSISTANCE_NOTIFICATIONS;
  payload: Assistance[];
}

interface ActionClearAssistanceNotifications {
  type: typeof CLEAR_ASSISTANCE_NOTIFICATIONS;
}

export type TypesActionsAssistance =
  | ActionListAssistance
  | ActionDeleteAssistance
  | ActionAssistanceNotifications
  | ActionClearAssistanceNotifications;
