import {
  TypesActionsTables,
  LIST_TABLES,
  ADD_TABLE,
  DELETE_TABLE,
  UPDATE_TABLE
} from '../../actions/tables/TablesTypes';
import { TablesState } from '../../models/tables/TablesState';
import { AccountSimplifiedPlanValues } from 'src/app/feature/Accounts/Interfaces/interfaces';

const initialState: TablesState = {
  tables: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  table: {
    _id: '',
    name: '',
    isEnable: true,
    account: AccountSimplifiedPlanValues
  }
};

export default function tablesReducer(
  state = initialState,
  action: TypesActionsTables
) {
  switch (action.type) {
    case LIST_TABLES: {
      const tables = action.payload;
      return {
        ...state,
        tables: tables
      };
    }
    case DELETE_TABLE: {
      const table = action.payload;
      return {
        ...state,
        tables: {
          docs: [...state.tables.docs.filter((e) => e._id !== table)]
        }
      };
    }
    case UPDATE_TABLE: {
      const table = action.payload;
      const index = state.tables.docs.findIndex(
        (todo) => todo._id === table._id
      );
      const newArray = [...state.tables.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.tables;
      const tables = {
        docs: newArray,
        ...rest
      };
      return {
        ...state, //copying the orignal state
        tables
      };
    }
    case ADD_TABLE: {
      const table = action.payload;
      return {
        ...state,
        table
      };
    }
    default:
      return state;
  }
}
