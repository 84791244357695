import {
  ADD_SETTING,
  UPDATE_SETTING,
  LIST_SETTINGS,
  CLEAR_SETTINGS,
  TypesActionsSettings
} from '../../actions/settings/SettingTypes';
import { SettingsState } from '../../models/settings/SettingsState';

const initialState: SettingsState = {
  settings: []
};

export default function settingsReducer(
  state = initialState,
  action: TypesActionsSettings
) {
  switch (action.type) {
    case LIST_SETTINGS: {
      const settings = action.payload;
      return {
        ...state,
        settings
      };
    }
    case UPDATE_SETTING: {
      const setting = action.payload;
      const index = state.settings.findIndex(
        (todo) => todo._id === setting._id
      );
      const settings = [...state.settings]; //making a new array
      settings[index] = setting; //changing value in the new array
      return {
        ...state, //copying the orignal state
        settings
      };
    }
    case ADD_SETTING: {
      const setting = action.payload;
      const settings = state.settings;
      settings.push(setting);
      return {
        ...state,
        settings
      };
    }
    case CLEAR_SETTINGS: {
      return {
        ...state,
        settings: []
      };
    }
    default:
      return state;
  }
}
