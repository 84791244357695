import {
  GET_ORDERS_GRAPH,
  GET_ORDERS_BY_FOOD,
  GET_ORDERS_BY_STATUS,
  GET_ORDERS_TRACKING,
  GET_SALES_BY_ACCOUNT,
  GET_SALES_BY_FOOD,
  GET_SALES_PER_MONTH,
  TypesActionsReport
} from '../../actions/reports/ReportTypes';
import { ReportsState } from '../../models/reports/ReportsState';

const initialState: ReportsState = {
  ordersGraph: [],
  ordersByFood: [],
  ordersByStatus: [],
  ordersTracking: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  salesByAccount: [],
  salesByFood: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  salesPerMonth: { data: [] }
};

export default function reportsReducer(
  state = initialState,
  action: TypesActionsReport
) {
  switch (action.type) {
    case GET_ORDERS_GRAPH: {
      const ordersGraph = action.payload;
      return {
        ...state,
        ordersGraph
      };
    }
    case GET_ORDERS_BY_STATUS: {
      const ordersByStatus = action.payload;
      return {
        ...state,
        ordersByStatus
      };
    }
    case GET_ORDERS_BY_FOOD: {
      const ordersByFood = action.payload;
      return {
        ...state,
        ordersByFood
      };
    }
    case GET_ORDERS_TRACKING: {
      const ordersTracking = action.payload;
      return {
        ...state,
        ordersTracking
      };
    }
    case GET_SALES_BY_ACCOUNT: {
      const salesByAccount = action.payload;
      return {
        ...state,
        salesByAccount
      };
    }
    case GET_SALES_BY_FOOD: {
      const salesByFood = action.payload;
      return {
        ...state,
        salesByFood
      };
    }
    case GET_SALES_PER_MONTH: {
      const salesPerMonth = action.payload;
      return {
        ...state,
        salesPerMonth
      };
    }
    default: {
      return state;
    }
  }
}
