import { Dashboard } from 'src/app/feature/DashBoard/models/DashBoard';

export const GET_DASHBOARD = 'GET_DASHBOARD';

interface ActionGetDashboard {
  type: typeof GET_DASHBOARD;
  payload: Dashboard;
}

export type TypesActionsDashboard = ActionGetDashboard;
