import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { UserAuth } from 'src/app/feature/Users/models/User';
import { UserAuthInitialValues } from 'src/app/feature/Users/interfaces/interfaces';
import { changeAccount } from 'src/app/core/redux/actions/users/UsersActions';
import { getInvoiceStatusAsync } from 'src/app/core/redux/actions/invoice/InvoiceActions';
import { UsersRole } from 'src/app/core/config/enums';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { Account } from 'src/app/feature/Accounts/models/Accounts';
import {
  useAppSelector,
  useAppDispatch
} from 'src/app/core/config/reduxCustomHooks';
import { clearAsistanceNotifications } from 'src/app/core/redux/actions/assistance/AssistanceActions';
import styles from './style.module.scss';
import { SettingsDao } from 'src/app/core/api/settings/dao/settings.dao';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const AccountName = (): JSX.Element => {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [actualAccount, setActualAccount] = useState<Account>(null);
  const [user, setUser] = useState<UserAuth>(UserAuthInitialValues);

  const redirect = () => {
    navigate('/');
  };
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.user.user);

  const getInfoUser = () => {
    const tmpUser: UserAuth = JSON.parse(localStorage.getItem('user'));
    const tmpAccount: Account =
      userState.accountList.length > 0
        ? userState.accountList.find((e) => e._id === tmpUser.selectedAccount)
        : null;
    setActualAccount(tmpAccount);
    setUser(tmpUser);
  };

  const changeAccountHandler = (value: string) => {
    Alert({
      title: 'Confirmar',
      text: '¿Está seguro de cambiar la cuenta?',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleClose();
        const data = await getInvoiceStatusAsync(value);
        const settings = await SettingsDao.list(value);
        if (settings.data.length) {
          user.settings = settings.data[0];
        } else {
          user.settings = null;
        }
        user.allowedAccount = data.data;
        dispatch(changeAccount(user, value, redirect));
        dispatch(clearAsistanceNotifications());
      }
    });
  };

  useEffect(() => {
    getInfoUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.selectedAccount]);

  return (
    <React.Fragment>
      {user.accountList.length > 1 ? (
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Hidden smDown>
            <UserBoxText>
              <UserBoxLabel variant="h3">
                {actualAccount ? actualAccount.name : 'Por favor espere...'}
              </UserBoxLabel>
            </UserBoxText>
          </Hidden>
          {/* <Hidden smDown> */}
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          {/* </Hidden> */}
        </UserBoxButton>
      ) : (
        <Typography variant="h3" sx={{ color: '#F7941E' }}>
          {actualAccount ? actualAccount.name : 'Por favor espere...'}
        </Typography>
      )}
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserBoxText>
            <UserBoxLabel variant="body1">Cambiar cuenta</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <Box sx={{ p: 1 }} component="nav">
          {user.role === UsersRole.OWNER && user.accountList.length > 1 ? (
            <List sx={{ display: 'flex', flexDirection: 'column' }}>
              {user.accountList.map((item) => {
                return (
                  <ListItemButton
                    className={styles.text_color}
                    key={item._id}
                    onClick={() => changeAccountHandler(item._id)}
                  >
                    {item.name}
                  </ListItemButton>
                );
              })}
            </List>
          ) : null}
        </Box>
        <Divider />
      </Popover>
    </React.Fragment>
  );
};

export default AccountName;
