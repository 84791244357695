import {
  OrderGraph,
  OrderByStatus,
  OrderByFood,
  OrderTrackingResponse,
  SalesByAccount,
  SalesByFoodResponse,
  SalesPerMonth
} from 'src/app/feature/Report/models/Report';

export const GET_ORDERS_GRAPH = 'GET_ORDERS_GRAPH';
export const GET_ORDERS_BY_STATUS = 'GET_ORDERS_BY_STATUS';
export const GET_ORDERS_BY_FOOD = 'GET_ORDERS_BY_FOOD';
export const GET_ORDERS_TRACKING = 'GET_ORDERS_TRACKING';
export const GET_SALES_BY_ACCOUNT = 'GET_SALES_BY_ACCOUNT';
export const GET_SALES_BY_FOOD = 'GET_SALES_BY_FOOD';
export const GET_SALES_PER_MONTH = 'GET_SALES_PER_MONTH';

interface ActionGetOrdersGraph {
  type: typeof GET_ORDERS_GRAPH;
  payload: OrderGraph[];
}
interface ActionGetOrdersByStatus {
  type: typeof GET_ORDERS_BY_STATUS;
  payload: OrderByStatus[];
}

interface ActionGetOrdersByFood {
  type: typeof GET_ORDERS_BY_FOOD;
  payload: OrderByFood[];
}

interface ActionGetOrderTracking {
  type: typeof GET_ORDERS_TRACKING;
  payload: OrderTrackingResponse;
}

interface ActionGetSalesByAccount {
  type: typeof GET_SALES_BY_ACCOUNT;
  payload: SalesByAccount[];
}

interface ActionGetSalesByFood {
  type: typeof GET_SALES_BY_FOOD;
  payload: SalesByFoodResponse;
}

interface ActionGetSalesPerMonth {
  type: typeof GET_SALES_PER_MONTH;
  payload: SalesPerMonth;
}

export type TypesActionsReport =
  | ActionGetOrdersGraph
  | ActionGetOrdersByStatus
  | ActionGetOrdersByFood
  | ActionGetOrderTracking
  | ActionGetSalesByAccount
  | ActionGetSalesByFood
  | ActionGetSalesPerMonth;
