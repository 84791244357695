import {
  TypesActionsInvoices,
  LIST_INVOICES,
  ADD_INVOICE,
  CLEAR_INVOICE
} from '../../actions/invoice/invoiceTypes';
import { InvoicesState } from '../../models/invoices/InvoiceState';
import { InvoiceInitialValues } from 'src/app/feature/Payments/interfaces/interfaces';

const initialState: InvoicesState = {
  invoices: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  invoice: InvoiceInitialValues
};

export default function invoicesReducer(
  state = initialState,
  action: TypesActionsInvoices
) {
  switch (action.type) {
    case LIST_INVOICES: {
      const invoices = action.payload;
      return {
        ...state,
        invoices
      };
    }
    case ADD_INVOICE: {
      const invoice = action.payload;
      return {
        ...state,
        invoice
      };
    }
    case CLEAR_INVOICE: {
      return {
        ...state,
        invoice: InvoiceInitialValues
      };
    }
    default:
      return state;
  }
}
