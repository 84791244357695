import { combineReducers } from 'redux';
import products from './product/productReducer';
import users from './users/userReducer';
import user from './user/userReducer';
import foodCategories from './foodCategories/foodCategoriesReducer';
import foodComplements from './foodComplements/foodComplementsReducer';
import foods from './foods/foodsReducer';
import accounts from './accounts/accountsReducer';
import tables from './tables/tableReducer';
import orders from './orders/orderReducer';
import dashboard from './dashboard/dashboardReducer';
import invoices from './invoices/invoiceReducer';
import assistance from './assistance/assistanceReducer';
import reports from './reports/reportsReducer';
import settings from './settings/settingsReducer';

export default combineReducers({
  products,
  user,
  users,
  foodCategories,
  accounts,
  foodComplements,
  foods,
  tables,
  orders,
  dashboard,
  invoices,
  assistance,
  reports,
  settings
});
