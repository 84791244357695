import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  MenuItem,
  Menu
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

import { changeAccount } from 'src/app/core/redux/actions/users/UsersActions';
import { UsersRole } from 'src/app/core/config/enums';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import {
  useAppSelector,
  useAppDispatch
} from 'src/app/core/config/reduxCustomHooks';
import { getInvoiceStatusAsync } from 'src/app/core/redux/actions/invoice/InvoiceActions';
import { clearAsistanceNotifications } from 'src/app/core/redux/actions/assistance/AssistanceActions';
import { clearSettings } from 'src/app/core/redux/actions/settings/SettingActions';
import { clearUserAuth } from 'src/app/core/redux/actions/users/UsersActions';
import { UserAuth } from 'src/app/feature/Users/models/User';
import { UserAuthInitialValues } from 'src/app/feature/Users/interfaces/interfaces';
import { Account } from 'src/app/feature/Accounts/models/Accounts';
import { SettingsDao } from 'src/app/core/api/settings/dao/settings.dao';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  let navigate = useNavigate();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<UserAuth>(UserAuthInitialValues);
  const [actualAccount, setActualAccount] = useState<Account>(null);
  const userState = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();
  const redirect = () => {
    navigate('/');
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const clearReduxStates = () => {
    dispatch(clearAsistanceNotifications());
    dispatch(clearSettings());
    dispatch(clearUserAuth());
  };

  const signOut = () => {
    const userAux: UserAuth = JSON.parse(localStorage.getItem('user'));
    localStorage.removeItem('user');
    localStorage.removeItem('notificationAssiatance');
    localStorage.removeItem('notificationOrders');
    clearReduxStates();
    navigate('/');
    if (userAux.role === UsersRole.KITCHEN) {
      setTimeout(() => {
        window.location.reload();
      }, 250);
    }
  };

  const getInfoUser = () => {
    const tmpUser: UserAuth = JSON.parse(localStorage.getItem('user'));
    const tmpAccount: Account =
      userState.accountList.length > 0
        ? userState.accountList.find((e) => e._id === tmpUser.selectedAccount)
        : null;
    setActualAccount(tmpAccount);
    setUser(tmpUser);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getInfoUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.selectedAccount]);

  const changeAccountHandler = (value: string) => {
    Alert({
      title: 'Confirmar',
      text: '¿Está seguro de cambiar la cuenta?',
      icon: 'info',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleCloseMenu();
        handleClose();
        const data = await getInvoiceStatusAsync(value);
        const settings = await SettingsDao.list(value);
        if (settings.data.length) {
          user.settings = settings.data[0];
        } else {
          user.settings = null;
        }
        user.allowedAccount = data.data;
        dispatch(changeAccount(user, value, redirect));
        dispatch(clearAsistanceNotifications());
      }
    });
  };

  return (
    <>
      {user._id !== '' ? (
        <>
          <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
            <Hidden mdDown>
              <UserBoxText>
                <UserBoxLabel variant="body1">{`${user.firstName} ${user.lastName}`}</UserBoxLabel>
                <UserBoxDescription variant="body2">
                  {user.role}
                </UserBoxDescription>
              </UserBoxText>
            </Hidden>
            <Hidden smUp>
              <UserBoxText>
                <UserBoxLabel variant="h3">
                  {actualAccount ? actualAccount.name : 'Por favor espere...'}
                </UserBoxLabel>
              </UserBoxText>
            </Hidden>
            {/* <Hidden smDown> */}
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
            {/* </Hidden> */}
          </UserBoxButton>
          <Popover
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuUserBox sx={{ minWidth: 210 }} display="flex">
              <UserBoxText>
                <UserBoxLabel variant="body1">{`${user.firstName} ${user.lastName}`}</UserBoxLabel>
                <UserBoxDescription variant="body2">
                  {user.role}
                </UserBoxDescription>
              </UserBoxText>
            </MenuUserBox>
            <Divider sx={{ mb: 0 }} />
            <List sx={{ p: 1 }} component="nav">
              <ListItem
                button
                to="/profile"
                component={NavLink}
                onClick={handleClose}
              >
                <AccountBoxTwoToneIcon fontSize="small" />
                <ListItemText primary="Perfil de Usuario" />
              </ListItem>
              {user.role === UsersRole.OWNER && user.accountList.length > 1 ? (
                <ListItem>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                      style={{ color: '#f49200', paddingLeft: 7 }}
                      onClick={handleClick}
                    >
                      <AccountBoxTwoToneIcon
                        fontSize="small"
                        sx={{ marginRight: '5px' }}
                      />
                      <ListItemText primary="Cambiar cuenta" />
                    </Button>
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {user.accountList.map((item) => {
                      return (
                        <MenuItem
                          key={item._id}
                          onClick={() => changeAccountHandler(item._id)}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </ListItem>
              ) : null}
            </List>
            <Divider />
            <Box sx={{ m: 1 }}>
              <Button color="secondary" fullWidth onClick={() => signOut()}>
                <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                Salir
              </Button>
            </Box>
          </Popover>
        </>
      ) : null}
    </>
  );
}

export default HeaderUserbox;
