import {
  TypesActionsFoodCategories,
  LIST_FOOD_CATEGORIES,
  DELETE_FOOD_CATEGORY,
  ADD_FOOD_CATEGORY,
  UPDATE_FOOD_CATEGORY
} from '../../actions/foodCategories/FoodCategoriesTypes';
import { FoodCategoriesState } from '../../models/foodCategories/FoodCategoriesState';

const initialState: FoodCategoriesState = {
  foodCategories: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  foodCategory: {
    _id: '',
    name: '',
    status: '',
    type: '',
    account: ''
  }
};

export default function foodCategoriesReducer(
  state = initialState,
  action: TypesActionsFoodCategories
) {
  switch (action.type) {
    case LIST_FOOD_CATEGORIES: {
      const foodCategories = action.payload;
      return {
        ...state,
        foodCategories: foodCategories
      };
    }
    case DELETE_FOOD_CATEGORY: {
      const foodCategory = action.payload;
      return {
        ...state,
        foodCategories: {
          docs: [
            ...state.foodCategories.docs.filter((e) => e._id !== foodCategory)
          ]
        }
      };
    }
    case UPDATE_FOOD_CATEGORY: {
      const foodCategory = action.payload;
      const index = state.foodCategories.docs.findIndex(
        (todo) => todo._id === foodCategory._id
      );
      const newArray = [...state.foodCategories.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.foodCategories;
      const foodCategories = {
        docs: newArray,
        ...rest
      };
      return {
        ...state, //copying the orignal state
        foodCategories
      };
    }
    case ADD_FOOD_CATEGORY: {
      const foodCategory = action.payload;
      return {
        ...state,
        foodCategory
      };
    }
    default:
      return state;
  }
}
