export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};

interface FinancialFormatProps {
  amount: number;
  format?: string;
  style?: string;
  currency?: string;
  maximumFractionDigits?: number;
}

export const financialFormat = ({
  amount,
  format = 'es-CR',
  style = 'currency',
  currency = 'CRC',
  maximumFractionDigits = 2
}: FinancialFormatProps) => {
  return new Intl.NumberFormat(format, {
    style,
    currency,
    maximumFractionDigits
  }).format(amount);
};

export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const sortMonthsArray = (array: any[]) => {
  //!NOTA: Para usar esta función se requiere que el array tenga como propiedad en cada objeto "month", que sea de 12 elementos y que esten en ingles los meses
  const newArray = array?.sort((a, b) => {
    return monthsArray.indexOf(a.month) - monthsArray.indexOf(b.month);
  });
  return newArray;
};

export const suprimeDuplicateElementsArray = (array: any[]) => {
  let newArray = array?.filter((item, index) => {
    return (
      index ===
      array.findIndex((obj) => {
        return JSON.stringify(obj) === JSON.stringify(item);
      })
    );
  });
  return newArray;
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const tieneInterseccion = (array1: any[], array2: any[]) => {
  for (const elemento of array1) {
    if (array2.includes(elemento)) {
      return true;
    }
  }
  for (const elemento of array2) {
    if (array1.includes(elemento)) {
      return true;
    }
  }
  return false;
};

export const getFee = (price: number, porcent: number) => {
  const aux = porcent / 100;
  return porcent > 0 ? price * aux : 0;
};

export const roundNumber = (value: number, decimals = 2) => {
  const aux = value.toFixed(decimals);
  return Number(aux);
};
