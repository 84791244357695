import axios from 'axios';

export const getToken = async () =>
  localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).accessToken
    : null;

export const getAuthorizationHeader = async () => {
  const token = await getToken();
  return { Authorization: `Bearer ${token}` };
};

const axiosIntance = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
  timeout: 30000
});

// For GET requests
axiosIntance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).accessToken
      : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
axiosIntance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.message === 'Unauthorized'
    ) {
      localStorage.removeItem('user');
      localStorage.removeItem('notificationAssiatance');
      localStorage.removeItem('notificationOrders');
      return (window.location.href = '/');
    }
    return Promise.reject(err);
  }
);

export { axiosIntance };
