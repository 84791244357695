import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import store from '../redux/store';

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
