import {
  LIST_INVOICES,
  ADD_INVOICE,
  CLEAR_INVOICE,
  TypesActionsInvoices
} from './invoiceTypes';
import { InvoiceDao } from 'src/app/core/api/invoice/dao/invoice.dao';
import { InvoiceRepository } from 'src/app/core/api/invoice/repository/invoice.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { UserAuth } from 'src/app/feature/Users/models/User';
import {
  Invoice,
  ResponseInvoiceProps
} from 'src/app/feature/Payments/models/Payment';
import { InvoiceStatusEnum } from 'src/app/core/config/enums';
import { updateUserAuth } from '../users/UsersActions';

function listInvoices(payload: ResponseInvoiceProps): TypesActionsInvoices {
  return {
    type: LIST_INVOICES,
    payload
  };
}

function addInvoce(payload: Invoice): TypesActionsInvoices {
  return {
    type: ADD_INVOICE,
    payload
  };
}

export function clearInvoice(): TypesActionsInvoices {
  return {
    type: CLEAR_INVOICE
  };
}

export async function getInvoiceStatusAsync(id: string) {
  try {
    const data = await InvoiceDao.getInvoiceStatus(id);
    if (!data.data.allowed) {
      Alert({
        title: 'Informacion',
        text: 'El restaurante presenta morosidad por mensualidad vencida, por favor realizar el pago.',
        icon: 'warning'
      });
    }
    return data;
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}

export function makePaymentAllowedAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispatch: Function) {
    try {
      await InvoiceRepository.makePayment(form);
      const user: UserAuth = JSON.parse(localStorage.getItem('user'));
      user.allowedAccount.allowed = true;
      user.allowedAccount.invoice.status = InvoiceStatusEnum.PAID;
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(updateUserAuth(user));
      Alert({ text: 'Pago realizado con éxito', timer: 3000 }).then(() =>
        callback()
      );
    } catch ({ request }) {
      const message = JSON.parse(request.response).message;
      if (message.message) {
        Alert({
          title: 'Error',
          text: message.message,
          icon: 'error'
        });
      } else {
        Alert({
          title: 'Error',
          text: message,
          icon: 'error'
        });
      }
      setValidate(true);
    }
  };
}

export function makePaymentAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispatch: Function) {
    try {
      await InvoiceRepository.makePayment(form);
      Alert({ text: 'Pago realizado con éxito', timer: 3000 }).then(() =>
        callback()
      );
    } catch ({ request }) {
      const message = JSON.parse(request.response).message;
      if (message.message) {
        Alert({
          title: 'Error',
          text: message.message,
          icon: 'error'
        });
      } else {
        Alert({
          title: 'Error',
          text: message,
          icon: 'error'
        });
      }
      setValidate(true);
    }
  };
}

export function listInvoicesAsync(
  page: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const invoices = await InvoiceDao.list(
      accountId,
      page,
      limit,
      search,
      filter
    );
    dispacth(listInvoices(invoices.data));
  };
}

export function showInvoiceAsync(id: string, callback: Function) {
  return async function (dispacth: Function) {
    try {
      const invoice = await InvoiceDao.show(id);
      dispacth(addInvoce(invoice.data));
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      callback();
    }
  };
}

export async function getInvoicePdf(id: string) {
  try {
    return await InvoiceDao.getPdf(id);
  } catch ({ request }) {
    Alert({
      title: 'Error',
      text: `${JSON.parse(request.response).message}`,
      icon: 'error'
    });
  }
}
