import {
  ADD_USER,
  LIST_USER,
  UPDATE_USER,
  TypesActionsUsers
} from './UsersTypesActions';
import {
  ADD_USER_AUTH,
  CHANGE_ACCOUNT,
  UPDATE_USER_AUTH,
  CLEAR_USER_AUTH,
  TypesActionsUser
} from '../user/UserTypesActions';
import { UserDao } from 'src/app/core/api/user/dao/user.dao';
import { AxiosResponse } from 'axios';
import { ResponseUsersProps } from 'src/app/feature/Users/models/User';
import { UserRepository } from 'src/app/core/api/user/repository/user.repository';
import Alert from 'src/app/shared/components/MultiplePorpuseAlert';
import { UserAuth, User } from 'src/app/feature/Users/models/User';
import {
  ProfileData,
  FormValuesSecurity
} from 'src/app/feature/ProfileEdit/interfaces/interfaces';
import { UsersRole } from 'src/app/core/config/enums';

function listUsers(payload: ResponseUsersProps): TypesActionsUsers {
  return {
    type: LIST_USER,
    payload
  };
}

function addNewUser(user: User): TypesActionsUsers {
  return {
    type: ADD_USER,
    payload: user
  };
}

function updateUser(user: User): TypesActionsUsers {
  return {
    type: UPDATE_USER,
    payload: user
  };
}

export function updateUserAuth(user: UserAuth): TypesActionsUser {
  return {
    type: UPDATE_USER_AUTH,
    payload: user
  };
}

function signIn(user: UserAuth, accessToken: string): TypesActionsUser {
  return {
    type: ADD_USER_AUTH,
    payload: user,
    accessToken
  };
}

export function changeAccount(
  user: UserAuth,
  selectedAccount: string,
  callback: Function
): TypesActionsUser {
  user.selectedAccount = selectedAccount;
  localStorage.setItem('user', JSON.stringify(user));
  callback();
  return {
    type: CHANGE_ACCOUNT,
    payload: user
  };
}

export function clearUserAuth(): TypesActionsUser {
  return {
    type: CLEAR_USER_AUTH
  };
}

export function listUsersAsync(
  currentPage: number,
  limit: number,
  search: string | null
) {
  return async function (dispacth: Function) {
    await UserDao.list(currentPage, limit, search).then(
      (response: AxiosResponse<ResponseUsersProps>) => {
        dispacth(listUsers(response.data));
      }
    );
  };
}

export async function CheckUserAsync(form: Object, callback: Function) {
  return await UserDao.SignIn(form)
    .then((response: AxiosResponse<UserAuth>) => {
      callback(response.data);
    })
    .catch(({ request }) => {
      if (request) {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
      } else {
        Alert({ title: 'Error', text: 'Ha ocurrido un error', icon: 'error' });
      }
    });
}

export function SignInAsync(
  user: UserAuth,
  callback: Function,
  checkAccount: Function,
  reloadWindow?: Function
) {
  return async function (dispacth: Function) {
    localStorage.removeItem('appTheme');
    try {
      localStorage.setItem('user', JSON.stringify(user));
      if (user.role === UsersRole.KITCHEN) {
        localStorage.setItem('appTheme', 'PureLightThemeKitchen');
      } else {
        localStorage.setItem('appTheme', 'PureLightTheme');
      }
      await checkAccount();
      dispacth(signIn(user, user.accessToken));
      callback();
      reloadWindow && reloadWindow();
    } catch (error) {
      Alert({
        title: 'Error',
        text: 'Ha ocurrido un error',
        icon: 'error'
      });
      localStorage.removeItem('user');
      localStorage.removeItem('notificationAssiatance');
      localStorage.removeItem('notificationOrders');
    }
  };
}

export function showUserAsync(id: string, callback: Function) {
  return function (dispacth: Function) {
    UserDao.show(id)
      .then((response: AxiosResponse<User>) => {
        dispacth(addNewUser(response.data));
      })
      .catch(({ request }) => {
        Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
        callback();
      });
  };
}

export function updateUsersAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const response: AxiosResponse<User> = await UserRepository.update(form);
      dispacth(updateUser(response.data));
      Alert({
        text: `Usuario "${response.data.firstName} ${response.data.lastName}" actualizado con éxito`,
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function recoveryPasswordStep1(mail: string) {
  return function () {
    UserRepository.recoveryPasswordStep1(mail)
      .then(() => {
        return Alert({ title: `Email enviado con éxito`, timer: 3000 });
      })
      .catch(({ request }) => {
        return Alert({
          title: 'Error',
          text: `${JSON.parse(request.response).message}`,
          icon: 'error'
        });
      });
  };
}

export function createUsersAsync(
  form: object,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      const user: AxiosResponse<User> = await UserRepository.create(form);

      dispacth(addNewUser(user.data));
      Alert({
        text: `Usuario "${user.data.firstName} ${user.data.lastName}" creado con éxito`,
        timer: 3000
      }).then(() => callback());
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function deleteUserAsync(data: object) {
  return function (dispacth: Function) {
    UserRepository.delete(data).then(async (response: AxiosResponse<User>) => {
      Alert({ text: `Usuario eliminado con éxito`, timer: 3000 });
      await UserDao.list(null, null, null).then(
        (response: AxiosResponse<ResponseUsersProps>) => {
          dispacth(listUsers(response.data));
        }
      );
    });
  };
}

export function changePasswordAsync(
  data: FormValuesSecurity,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function () {
    try {
      await UserRepository.changePassword(data);
      Alert({ text: `Contraseña actualizada`, timer: 3000 }).then(() => {
        callback(data);
      });
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}

export function updateProfileAsync(
  form: ProfileData,
  callback: Function,
  setValidate: (value: boolean) => void
) {
  return async function (dispacth: Function) {
    try {
      await UserRepository.updateProfile(form);
      let tmpuser: UserAuth = JSON.parse(localStorage.getItem('user'));
      tmpuser.firstName = form.firstName;
      tmpuser.lastName = form.lastName;
      tmpuser.phone = form.phone;
      tmpuser.email = form.email;
      if (form.billTo) tmpuser.billTo = form.billTo;
      localStorage.setItem('user', JSON.stringify(tmpuser));
      dispacth(updateUserAuth(tmpuser));
      Alert({ text: `Usuario actualizado con éxito`, timer: 3000 }).then(() => {
        callback();
      });
    } catch ({ request }) {
      Alert({
        title: 'Error',
        text: `${JSON.parse(request.response).message}`,
        icon: 'error'
      });
      setValidate(true);
    }
  };
}
