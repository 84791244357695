import {
  UPDATE_ASSISTANCE,
  LIST_ASSISTANCES,
  ASSISTANCE_NOTIFICATIONS,
  CLEAR_ASSISTANCE_NOTIFICATIONS,
  TypesActionsAssistance
} from '../../actions/assistance/AssistanceTypes';
import { AssistanceState } from '../../models/assistance/AssistanceState';

const initialState: AssistanceState = {
  assistances: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  assistance: null,
  assistanceNotifications: []
};

export default function assistanceReducer(
  state = initialState,
  action: TypesActionsAssistance
) {
  switch (action.type) {
    case LIST_ASSISTANCES: {
      const assistances = action.payload;
      return {
        ...state,
        assistances
      };
    }
    case UPDATE_ASSISTANCE: {
      const assistance = action.payload;
      return {
        ...state,
        assistances: {
          ...state.assistances,
          docs: [...state.assistances.docs.filter((e) => e._id !== assistance)],
          totalDocs: state.assistances.totalDocs - 1
        }
      };
    }
    case ASSISTANCE_NOTIFICATIONS: {
      const assistanceNotifications = action.payload;
      return {
        ...state,
        assistanceNotifications
      };
    }
    case CLEAR_ASSISTANCE_NOTIFICATIONS: {
      return {
        ...state,
        assistanceNotifications: []
      };
    }
    default:
      return state;
  }
}
