import {
  Table,
  ResponseTablesProps
} from 'src/app/feature/Tables/models/Tables';

export const LIST_TABLES = 'LIST_TABLES';
export const ADD_TABLE = 'ADD_TABLE';
export const DELETE_TABLE = 'DELETE_TABLE';
export const UPDATE_TABLE = 'UPDATE_TABLE';

interface ActionListTables {
  type: typeof LIST_TABLES;
  payload: ResponseTablesProps;
}

interface ActionAddTables {
  type: typeof ADD_TABLE;
  payload: Table;
}

interface ActionDeleteTables {
  type: typeof DELETE_TABLE;
  payload: string;
}
interface ActionUpdateTables {
  type: typeof UPDATE_TABLE;
  payload: Table;
}


export type TypesActionsTables =
  | ActionListTables
  | ActionAddTables
  | ActionDeleteTables
  | ActionUpdateTables;
