import { AxiosResponse } from 'axios';
import { axiosIntance } from '../../../config/AxiosConfig';

export const OrdersDao = {
  list: (
    accountId: string,
    page: number | string | null,
    limit: number | string | null,
    search: string | null,
    filter: string | null
  ) => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }

    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT;
    }
    return axiosIntance.get(
      `/orders?page=${page}&limit=${limit}&account=${accountId}${query}${filter}`
    );
  },
  show: (id: string) => {
    return axiosIntance.get(`/orders/${id}`);
  },
  getList: (accountId: string) => {
    return axiosIntance.get(`/orders/list?account=${accountId}`);
  },
  getAccountsList: (accountId: string) => {
    return axiosIntance.get(`/${accountId}/accounts/list`);
  },
  panel: (arrayCategories?: string) => {
    let categoryId = '';
    if (arrayCategories && arrayCategories !== '[]') {
      categoryId = `?categoryId=${arrayCategories}`;
    }
    return axiosIntance.get(`/orders/kitchen/panel${categoryId}`);
  },
  getExcel: (
    accountId: string,
    search: string | null,
    filter: string | null
  ): Promise<AxiosResponse<Blob>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }
    if (filter) {
      filter = `&${filter}`;
    } else {
      filter = '';
    }
    return axiosIntance.get(
      `/orders/pdf?account=${accountId}${query}${filter}`,
      {
        responseType: 'blob' // important
      }
    );
  }
};
